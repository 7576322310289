import VueRouter from "vue-router";
import Login from "./pages/auth/Login.vue";
import Home from "./pages/home/index.vue";
import Dash from "./pages/home/newdash.vue";

import Los from "./pages/los/dashboard.vue";
import Applications from "./pages/los/applications/index.vue";
import ApplicationId from "./pages/los/applications/id.vue";
import Loans from "./pages/los/loans/index.vue";
import Map from "./pages/los/map.vue";
import LoanId from "./pages/los/loans/id.vue";
import AppLoanId from "./pages/los/loans/appLoanId.vue";
import WebLoanId from "./pages/los/loans/webLoanId.vue";

import Blog from "./pages/marketing/blog/index.vue";
import BlogCreate from "./pages/marketing/blog/create.vue";
import BlogUpdate from "./pages/marketing/blog/update.vue";
import Crm from "./pages/marketing/crm/index.vue";
import CrmId from "./pages/marketing/crm/id.vue";
import CompanyId from "./pages/marketing/companyid.vue";
import MarketingSearch from "./pages/marketing/index.vue";
import MarketingRank from "./pages/marketing/rank.vue";
import MarketingId from "./pages/marketing/id.vue";
import MarketingCode from "./pages/marketing/zipcode.vue";
import MarketingLeads from "./pages/marketing/leads.vue";
import MarketingLeadsId from "./pages/marketing/leadId.vue";
import MarketingLenders from "./pages/marketing/lenders.vue";
import MarketingLendersId from "./pages/marketing/lendersId.vue";

import Data from "./pages/data/index.vue";
import Companies from "./pages/data/companies.vue";
import CompanyId1 from "./pages/data/companiesid.vue";
import Analytics from "./pages/data/analytics.vue";
import AnalyticsId from "./pages/data/analyticsid.vue";
import Flippers from "./pages/data/Flippers.vue";
import AtomId from "./pages/data/Flippersid.vue";
import PropertyFlips from "./pages/data/PropertyFlips.vue";
import PropertyFlipsId from "./pages/data/PropertyFlipsid.vue";
import WildcatLoans from "./pages/data/WildcatLoans.vue";
import WildcatLoansId from "./pages/data/WildcatLoansid.vue";

import Users from "./components/Users.vue";

import LosDownLoad from "./components/LosDownLoad.vue";
import LoanReport from "./components/LoanReport.vue";

import Automations from "./pages/automations/dashboard.vue";
import Projects from "./pages/automations/projects.vue";
import Project from "./pages/automations/project.vue";
import Snaps from "./pages/automations/snaps.vue";
import Snap from "./pages/automations/snap.vue";
import Schedule from "./pages/automations/schedule.vue";
import LogsTimeline from "./pages/automations/timeline.vue"
import AutoClassForm from "./pages/automations/AutoClassForm";
import AutoScheduleForm from "./pages/automations/AutoScheduleForm";
import AutoConfigInstance from "./pages/automations/AutoConfigInstance";
import AutoConfigForm from "./pages/automations/AutoConfigForm";
import AutoConfigAssignForm from "./pages/automations/AutoConfigAssignForm";
import MapComponent from "./components/MapComponent.vue";
import Settings from "./pages/settings/index";

// import Projectlist from "./components/Projectlist.vue";
// import Project from "./components/Project.vue";
// import Snaplist from "./components/Snaplist.vue";
// import Snap from "./components/Snap.vue";
// import Schedule from "./components/Schedule.vue";
// import AutoConfigForm from "./components/AutoConfigForm";
// import AutoConfigAssignForm from "./components/AutoConfigAssignForm";
// import AutoClassForm from "./components/AutoClassForm";
// import AutoScheduleForm from "./components/AutoScheduleForm";
// import AutoConfigInstance from "./components/AutoConfigInstance";

import Apps from "./components/Apps.vue";
import Warehouse from "./components/Warehouse.vue";
import Property from "./components/Property.vue";

import { authGuard } from "./auth/authGuard";
import { Timeline } from "element-ui";

let routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/dash",
    props: true,
    name: "dash",
    component: Dash,
    beforeEnter: authGuard,
  },
  {
    path: "/los",
    redirect: "/los/applications",
  },
  {
    path: "/los/dashboard",
    name: "los",
    component: Los,
    beforeEnter: authGuard,
  },
  {
    path: "/los/applications",
    props: true,
    name: "applications",
    component: Applications,
    beforeEnter: authGuard,
  },
  {
    path: "/los/applications/:id",
    props: true,
    name: "applicationid",
    component: ApplicationId,
    beforeEnter: authGuard,
  },
  {
    path: "/los/loans",
    name: "loans",
    component: Loans,
    beforeEnter: authGuard,
  },
  {
    path: "/los/map",
    name: "mapcomponent",
    component: Map,
    beforeEnter: authGuard,
  },
  {
    path: "/los/loans/:id",
    props: true,
    name: "AppLoanId",
    component: AppLoanId,
    beforeEnter: authGuard,
  },
  {
    path: "/los/web/loans/:id",
    props: true,
    name: "WebLoanId",
    component: WebLoanId,
    beforeEnter: authGuard,
  },
  {
    path: "/los/loans/:id/:loanId",
    props: true,
    name: "LoanId",
    component: LoanId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/blog",
    props: true,
    name: "blog",
    component: Blog,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/blog/create",
    props: true,
    name: "blogCreate",
    component: BlogCreate,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/blog/:id",
    props: true,
    name: "blogEdit",
    component: BlogUpdate,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/crm",
    props: true,
    name: "crm",
    component: Crm,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/crm/:id",
    props: true,
    name: "crmid",
    component: CrmId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/company/:id",
    props: true,
    name: "CompanyId",
    component: CompanyId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/company/:id",
    props: true,
    name: "CompanyId1",
    component: CompanyId1,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing",
    redirect: "/marketing/crm",
  },
  {
    path: "/marketing/search",
    name: "marketingSearch",
    component: MarketingSearch,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/rank",
    name: "marketingRank",
    component: MarketingRank,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/leads",
    name: "marketingLeads",
    component: MarketingLeads,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/leads/:id",
    props: true,
    name: "marketingLeadsId",
    component: MarketingLeadsId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/lenders",
    name: "marketingLenders",
    component: MarketingLenders,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/lenders/:id",
    props: true,
    name: "marketingLendersId",
    component: MarketingLendersId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/:id",
    props: true,
    name: "marketingid",
    component: MarketingId,
    beforeEnter: authGuard,
  },
  {
    path: "/marketing/zipcode/:id",
    props: true,
    name: "marketingzipcode",
    component: MarketingCode,
    beforeEnter: authGuard,
  },
  {
    path: "/data",
    name: "data",
    component: Data,
    beforeEnter: authGuard,
  },
  {
    path: "/data/companies",
    name: "companies",
    component: Companies,
    beforeEnter: authGuard,
  },
  {
    path: "/data/analytics",
    name: "Listing Analytics, MLS, OH Sample",
    component: Analytics,
    beforeEnter: authGuard,
  },
  {
    path: "/data/listing_analytics/detail/:id",
    props: true,
    name: "AnalyticsId",
    component: AnalyticsId,
    beforeEnter: authGuard,
  },
  {
    path: "/data/property_flips/detail/:id",
    props: true,
    name: "PropertyFlipsId",
    component: PropertyFlipsId,
    beforeEnter: authGuard,
  },
  {
    path: "/data/attom_contacts/detail/:id",
    props: true,
    name: "AtomId",
    component: AtomId,
    beforeEnter: authGuard,
  },
  {
    path: "/data/attom_contacts",
    name: "Property Flippers, TX & OH",
    component: Flippers,
    beforeEnter: authGuard,
  },
  {
    path: "/data/property_flips",
    name: "Historical Property Flips, TX & OH",
    component: PropertyFlips,
    beforeEnter: authGuard,
  },
  {
    path: "/data/wildcat_loans",
    name: "Wildcat Historical Loans",
    component: WildcatLoans,
    beforeEnter: authGuard,
  },
  {
    path: "/data/wildcat_loan/detail/:id",
    props: true,
    name: "WildcatLoansId",
    component: WildcatLoansId,
    beforeEnter: authGuard,
  },
  {
    path: "/apps",
    name: "apps",
    component: Apps,
    beforeEnter: authGuard,
  },
  {
    path: "/apps/losDownLoad",
    props: true,
    name: "losDownLoad",
    component: LosDownLoad,
  },
  {
    path: "/apps/loanReport",
    props: true,
    name: "loanReport",
    component: LoanReport,
  },
  {
    path: "/automations",
    redirect: "/automations/dashboard",
  },
  {
    path: "/automations/dashboard",
    name: "automations",
    component: Automations,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/projects",
    name: "projects",
    component: Projects,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/projects/:id",
    props: true,
    name: "project",
    component: Project,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/snaps",
    name: "snaps",
    component: Snaps,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/snaps/:id",
    props: true,
    name: "snap",
    component: Snap,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/schedule",
    name: "schedule",
    component: Schedule,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/timeline",
    name: "timeline",
    component: LogsTimeline,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/autoclass/:id?/:preview?",
    props: true,
    name: "autoClassForm",
    component: AutoClassForm,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/autoschedule/:id?/:preview?",
    props: true,
    name: "autoScheduleForm",
    component: AutoScheduleForm,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/autoconfig-instance/:id?/:preview?",
    props: true,
    name: "autoConfigInstance",
    component: AutoConfigInstance,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/autoconfig/:id?/:preview?",
    props: true,
    name: "autoConfigForm",
    component: AutoConfigForm,
    beforeEnter: authGuard,
  },
  {
    path: "/automations/autoconfig-assign/:id?/:preview?",
    //props: { autoConfigId: autoConfigId },
    props: (route) => ({
      ...route.params,
    }),
    name: "autoConfigAssignForm",
    component: AutoConfigAssignForm,
    beforeEnter: authGuard,
  },

  {
    path: "/settings",
    name: "settings",
    component: Settings,
    beforeEnter: authGuard,
  },
  // {
  //   path: "/autoconfig/:id?/:preview?",
  //   props: true,
  //   name: "autoConfigForm",
  //   component: AutoConfigForm,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/autoconfig-assign/:id?/:preview?",
  //   //props: { autoConfigId: autoConfigId },
  //   props: (route) => ({
  //     ...route.params,
  //   }),
  //   name: "autoConfigAssignForm",
  //   component: AutoConfigAssignForm,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/autoclass/:id?/:preview?",
  //   props: true,
  //   name: "autoClassForm",
  //   component: AutoClassForm,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/autoschedule/:id?/:preview?",
  //   props: true,
  //   name: "autoScheduleForm",
  //   component: AutoScheduleForm,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/autoconfig-instance/:id?/:preview?",
  //   props: true,
  //   name: "autoConfigInstance",
  //   component: AutoConfigInstance,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/project/:id",
  //   props: true,
  //   name: "project",
  //   component: Project,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/snap/:id",
  //   props: true,
  //   name: "snap",
  //   component: Snap,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/snaps",
  //   name: "snaplist",
  //   component: Snaplist,
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/schedule",
  //   name: "schedule",
  //   component: Schedule,
  //   beforeEnter: authGuard,
  // },
  {
    path: "/warehouses",
    name: "warehouse",
    component: Warehouse,
    beforeEnter: authGuard,
  },
  {
    path: "/property/:propertyid",
    props: true,
    name: "property",
    component: Property,
    beforeEnter: authGuard,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    beforeEnter: authGuard,
  },
];

export default new VueRouter({
  routes,
  mode: "history",
});
