import { render, staticRenderFns } from "./leadId.vue?vue&type=template&id=bd7e9752&scoped=true"
import script from "./leadId.vue?vue&type=script&lang=js"
export * from "./leadId.vue?vue&type=script&lang=js"
import style0 from "./leadId.vue?vue&type=style&index=0&id=bd7e9752&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd7e9752",
  null
  
)

export default component.exports