<template>
  <div
    class="full-height pa-10 d-flex flex-column justify-start align-start"
    style="margin-left: 56px"
  >
    <div v-if="loading">
      <loader />
    </div>

    <div v-if="!loading">
      <v-card elevation="2">
        <v-card-title> Automation Activity </v-card-title>

        <v-card-subtitle>
          {{ createdLoansCount }} Loan Created, {{ updatedLoansCount }} Loan
          Updated and {{ dataUpdated }} data syncs have occurred today.
        </v-card-subtitle>
      </v-card>

      <v-card elevation="2" class="mt-4">
        <v-card-title> Automation Timeline </v-card-title>

        <v-tabs background-color="transparent">
          <v-tab @click="setTab('All')">All</v-tab>
          <v-tab @click="setTab('PROXY_PICS')">Proxy Pics</v-tab>
          <v-tab @click="setTab('FORECASA_COMPANY')">Forecasa Company</v-tab>
          <v-tab @click="setTab('MA_UPDATE_SYNC')">MA Update Sync</v-tab>
          <v-tab @click="setTab('MA_CREATE_SYNC')">MA Create Sync</v-tab>
        </v-tabs>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">Most Recent</div>

          <v-progress-linear
            v-if="tabLoader"
            indeterminate
            color="blue"
            class="mb-2"
          ></v-progress-linear>

          <v-timeline v-if="!tabLoader" dense>
            <v-timeline-item
              v-for="logItem of filteredLogObject"
              :key="logItem.id"
              :color="logItem.success ? 'blue' : 'red'"
              small
              right
            >
              <template v-if="logItem.type === 'PROXY_PICS'">
                <span class="text-gray-700 font-bold align-self-end">
                  <strong>Proxy Pics</strong> |
                  {{
                    logItem.createdAt
                      .toDate()
                      .toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                  }}
                </span>
                <div class="mt-2 text-caption">
                  Address: {{ logItem.propertyAddress }}<br />
                  Sent to Proxy Pics: {{ logItem.sentToProxyPics ? "Yes" : "No"
                  }}<br />
                  Contact: {{ logItem.contactName }} ({{
                    logItem.contactPhoneNumber
                  }})
                </div>
              </template>

              <template v-if="logItem.type === 'FORECASA_COMPANY'">
                <span class="text-gray-700 font-bold align-self-end">
                  <strong>Forecasa Company</strong> |
                  {{
                    logItem.createdAt
                      .toDate()
                      .toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                  }}
                </span>
                <div class="mt-2 text-caption">
                  Company Id: {{ logItem.forecasa_id }}<br />
                  Company Name: {{ logItem.company }}<br />
                </div>
              </template>

              <template v-else-if="logItem.type === 'MA_UPDATE_SYNC'">
                <span class="text-gray-700 font-bold align-self-end">
                  <strong>MA Update Sync</strong> |
                  {{
                    logItem.createdAt
                      .toDate()
                      .toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                  }}
                </span>
                <div class="mt-2 text-caption">
                  Loan Title: {{ logItem.loanTitle }}<br />
                  Loan Id: {{ logItem.maLoanId }}
                </div>
                <div class="mt-2 text-caption" v-if="logItem.success === false">
                  {{ formatErrorMessage(logItem.errorMessage) }}<br />
                </div>
              </template>

              <template v-else-if="logItem.type === 'MA_CREATE_SYNC'">
                <span class="text-gray-700 font-bold align-self-end">
                  <strong>MA Create Sync</strong> |
                  {{
                    logItem.createdAt
                      .toDate()
                      .toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                  }}
                </span>
                <div class="mt-2 text-caption">
                  Loan Title: {{ logItem.title }}<br />
                  Loan Id: {{ logItem.maLoanId }}
                </div>
                <div class="mt-2 text-caption" v-if="logItem.success === false">
                  Error: {{ logItem.errorMessage }}<br />
                </div>
              </template>
            </v-timeline-item>
          </v-timeline>

          <v-btn v-if="!tabLoader && hasMoreLogs" @click="loadMoreLogs">
            Load More
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import { firebaseDB } from "../../auth/firebase-auth";

export default {
  name: "Timeline",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      tabLoader: false,
      createdLoansCount: 0,
      updatedLoansCount: 0,
      dataUpdated: 0,
      selectedTab: "All",
      filteredLogObject: [],
      currentLogCount: 50,
      hasMoreLogs: true,
      losLogObject: [],
    };
  },

  mounted() {
    this.fetchLosDetail();
    this.filterLogs('All'); // Default to filtering by "All" logs
  },

  // watch: {
  //   losLogObject: {
  //     handler() {
  //       this.updateCounts(); // Update counts whenever losLogObject changes
  //       this.filterLogs(this.selectedTab);
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    setTab(tab) {
      this.selectedTab = tab;
      this.filterLogs(tab);
    },
    async fetchLosDetail() {
      this.loading = true;
      try {
        const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD

        // Fetch logs
        const logdata = await firebaseDB
          .firestore()
          .collection("crebridLogs")
          .orderBy("createdAt", "desc")
          .limit(this.currentLogCount)
          .get();

        this.losLogObject = logdata.docs.map((doc) => doc.data());

        // Fetch counts for today
        const createdLoansDoc = await firebaseDB
          .firestore()
          .collection("createdLoansCount")
          .doc(today)
          .get();
        this.createdLoansCount = createdLoansDoc.exists
          ? createdLoansDoc.data().count
          : 0;

        const updatedLoansDoc = await firebaseDB
          .firestore()
          .collection("updatedLoansCount")
          .doc(today)
          .get();
        this.updatedLoansCount = updatedLoansDoc.exists
          ? updatedLoansDoc.data().count
          : 0;

        const apiHitsDoc = await firebaseDB
          .firestore()
          .collection("apiHits")
          .doc(today)
          .get();
        this.dataUpdated = apiHitsDoc.exists ? apiHitsDoc.data().count : 0;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error fetching logs:", error);
      }
    },
    // updateCounts() {
    //   this.createdLoansCount = this.losLogObject.filter(
    //     (log) => log.type === "MA_CREATE_SYNC"
    //   ).length;
    //   this.updatedLoansCount = this.losLogObject.filter(
    //     (log) => log.type === "MA_UPDATE_SYNC"
    //   ).length;
    //   this.dataUpdated = this.losLogObject.length; // Total logs fetched
    // },
    formatErrorMessage(rawMessage) {
      console.log(rawMessage);

      if (
        rawMessage === "You have reached your secondly limit." ||
        rawMessage === "There was a problem with the request." ||
        rawMessage === "Unknown error"
      ) {
        return `Error : ${rawMessage}`;
      } else {
        const startIndex = rawMessage.indexOf("[");
        const endIndex = rawMessage.lastIndexOf("]");

        if (startIndex > -1 && endIndex > -1) {
          try {
            const jsonString = rawMessage.substring(startIndex, endIndex + 1);
            const errors = JSON.parse(jsonString);
            const errorType = errors[0]?.error;
            const message = errors[0]?.message;
            const formattedMessage = `Error: ${errorType}\nMessage: ${message}`;
            return formattedMessage;
          } catch (e) {
            console.error("Error parsing JSON:", e);
            return "An error occurred while processing the error message.";
          }
        }
      }

      return `Error : ${rawMessage}`;
    },
    async filterLogs(type) {
      this.selectedTab = type;
      this.tabLoader = true;
      if (type === "All") {
        await this.loadLogs(); // Load the latest 50 logs
      } else {
        await this.loadLogsByType(type); // Load logs of the specific type
      }

      this.tabLoader = false;
    },
    async loadLogs() {
      this.tabLoader = true;
      const logdata = await firebaseDB
        .firestore()
        .collection("crebridLogs")
        .orderBy("createdAt", "desc")
        .limit(this.currentLogCount)
        .get();

      this.filteredLogObject = logdata.docs.map((doc) => doc.data());
      this.hasMoreLogs = logdata.docs.length === this.currentLogCount;
      this.tabLoader = false;
    },
    async loadLogsByType(type) {
      this.tabLoader = true;
      const logdata = await firebaseDB
        .firestore()
        .collection("crebridLogs")
        .where("type", "==", type)
        .orderBy("createdAt", "desc")
        .limit(this.currentLogCount)
        .get();

      this.filteredLogObject = logdata.docs.map((doc) => doc.data());
      this.hasMoreLogs = logdata.docs.length === this.currentLogCount;
      this.tabLoader = false;
    },
    async loadMoreLogs() {
      this.currentLogCount += 50;
      this.tabLoader = true;
      await this.filterLogs(this.selectedTab);
    },
  },
};
</script>
